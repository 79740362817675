<template>
  <div
    class="col-12"
  >
    <div class="card card--profile-tasks">
      <div
        v-b-toggle="`collapseOtherNotifications`"
        class="card-header"
      >
        <h3 class="float-start"> Archived notifications <span
          v-if="!loading"
          class="badge bg-light-info"
        >{{ total }}</span>
        </h3>
        <div class="heading-elements heading-elements-custom">
          <ul class="list-inline mb-0 disp-in ms-2">
            <li>
              <a data-action="collapse"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              ><polyline points="6 9 12 15 18 9" /></svg></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseOtherNotifications`"
        :visible="!fields.find(e => e.name == 'Other notifications') || fields.find(e => e.name == 'Other notifications').visibility"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="table-responsive"
          >
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Date entry</th>
                  <th>Title</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.title }}</td>
                  <td class="text-end">
                    <a
                      @click="$emit('showAlert', item)"
                    >
                      <i data-feather="eye" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-1">
            <Paginator
              :total="total"
              :store="'-'"
              @loading="reloadData"
            />
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import Paginator from '@/views/back/partials/Paginator.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    Paginator,
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: false,
      selectedItems: [],
      clicked: false,
      items: [],
      total: 0,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      loggedUser: 'auth/admin',
      items: 'alerts/general',
    }),
  },
  async mounted() {
    this.reloadData()

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    async reloadData() {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, { archived: true })
      this.items = resp.data.data.data
      this.total = resp.data.total
    },
  },
}
</script>
